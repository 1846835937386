.nc-form {
}

.nc-input-group {
    position: relative;

    &__label {
        transform: translate3d(0, 23px, 0);
        transition:
            color 150ms cubic-bezier(0.4, 0, 0.2, 1),
            transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
            -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
        pointer-events: none;
        font-size: 16px;

        ~ .nc-input-group__field {
            margin-top: 8px;
        }
    }

    &__field {
        position: relative;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        outline: 0;
        border: 0;
        border-bottom: 1px solid var(--ion-color-step-150, #d9d9d9);
        z-index: 2;
        background: transparent;

        &--has-value,
        &:focus {
            ~ .nc-input-group__label {
                transform-origin: left top;
                transform: translate3d(0, 30%, 0) scale(0.75);
            }
        }
    }

    ion-icon[slot='end'] {
        margin-left: 8px;
    }

    &_ion-item {
        padding-left: calc(var(--nci-item-padding-start) + var(--ion-safe-area-left, 0px));
    }
}

.nc-action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 9px;
    line-height: 12px;

    a {
        // font-family: 'Literal Bold';
        text-decoration: none;
    }
}

.nc-form-container {
    // background-color: var(--ion-color-light);
    height: 100%;
    // Контейнер находится внутри общего родительского, у которого отступ 16 и мы не можем сменить его фон, так как он используется
    // также в старом дизайне. Поэтому мы перекрываем фон родителя расширяя контейнер.
    // margin: -16px;
    padding: 32px;

    .nc-container {
        background-color: white;
        border: 1px solid var(--ion-color-light-shade);
        border-radius: 8px;
        padding: 32px;
        margin-bottom: 24px;

        h2 {
            font-size: 1rem;
            color: var(--ion-color-dark);
        }

        h2:first-of-type {
            margin-top: 0;
            /* Установите нужное значение отступа */
        }

        p {
            color: var(--ion-color-medium);
        }
    }
}
